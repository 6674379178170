.profile{
    display: flex;
}

.profileRight{
    flex: 9;
}

.profileRightBottom{
    display: flex;
}

.profileCover{
   height: 320px;
   background-image: url('../../../public/img4.jpg');
   background-size: cover;
   width: 100%;
   display: flex;
   align-items: center;
   justify-content: center;
   flex-direction: column;
   gap: 5px;


}
.coverPic{
    width: 100%;
    height: 250px;
    object-fit: cover;
}
.profilePic{
    width: 100%;
    height: 200px;
    width: 200px;
    border-radius: 50%;
    object-fit: cover;
    border: 2px solid white;

}