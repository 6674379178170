.topbarContainer{
 height: 50px;
 width: 100%;
 background-color:#1877f2 ;
 display: flex;
 align-items: center;
 position: sticky;
 top: 0;

}

.topbarLeft{
    flex: 3;
}
.topbarCenter{
    flex: 5;
    width: 100%;
  height: 30px;
  background-color: aliceblue;
  border-radius: 10px;  
  display: flex;
  align-items: center;
   
}

.searchBar{
    width: 90%;
    outline: none;
    border: none;
    margin-left: 10px;
    background-color: aliceblue;
}

.topbarRight{
    flex: 4;
    display: flex;
    align-items: center;
    justify-content: space-around;
    color: aliceblue;
}

.topbarImg{
  height: 40px;
  width: 40px;
  border-radius: 50%;
  object-fit: cover;

}

.logo{
    font-style: 24px;
    font-weight: bold;
    margin-left: 20px;
    color: white;
    cursor: pointer;
    font-family: 'Roboto', sans-serif;
}

.topbarLink{
    margin-right: 10px;
    font-size: 14px;
    cursor: pointer;
}
.topbarIcons{
 display: flex;
}
.topbarIconItem{
  margin-right: 15px;
  cursor: pointer;
  position: relative;

}
.topbarIconBadge{
 background-color: red;
 width: 15px;
 height: 15px;
 border-radius: 50%;
 position: absolute;
 top: -5px;
 right: -5px;
 display: flex;
 align-items: center;
 justify-content: center;
 font-size: 12px;

}
.logout{
  border: none;
  outline: none;
  background-color: #18f25d;
  padding: 5px;
  border-radius: 5px;
}
