.sharedContainer{
    width: 100%;
    height: 170px;
    border-radius: 10px;
     box-shadow: 0px 0px 3px 2px rgba(166,161,161,0.74);
-webkit-box-shadow: 0px 0px 3px 2px rgba(166,161,161,0.74);
-moz-box-shadow: 0px 0px 3px 2px rgba(166,161,161,0.74);
display: flex;align-items: center;
justify-content: center;
}


.sharedWrapper{
    padding: 10px;

}

.sharedTop{
    display: flex;
    align-items: center;

}

.sharedInput{
    border: none;
    width: 100%;
    margin: 5px;
    height: 50px;
    outline: none;
    font-size: 20px;
    border-bottom: 1px solid black;
}


.sharedBottom{
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.sharedOptions{
    display: flex;
    margin-left: 55px;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    
}
.sharedOption{
    display: flex;
    align-items: center;
    gap: 5px;
    margin-right: 15px;
    cursor: pointer;
    
}
.sharedProfileImg{
height: 50px;
width: 50px;
border-radius: 50%;
object-fit: cover;

}
.shareOptionImg{
    height: 20px;
    width: 20px;
    object-fit: cover;
}

.shareButton{
    padding: 5px;
    border: none;
    background-color: rgb(1, 255, 170);
    border-radius: 5px;
    font-weight: 500;
    cursor: pointer;
    margin-right: 20px;
}
.progress{
    display: flex;
    align-items: center;
    justify-content: center;
}